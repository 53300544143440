






















import { Vue, Prop, Component } from 'vue-property-decorator'
import { FormSchema } from 'vue-form-generator'
import { rolesTranslation } from '@/config/types/roles'
import AdminAPI from '@/api/requests/admin'
import _ from 'lodash'
import { roles } from '@/config/types/roles'
import UserFactory from '@/factories/user'
import { moodleTranslation } from '@/config/types/moodleRights'
import { regFIO, regMail } from '@/config/default/regex'
import { dictMapper } from '@/store/modules/dicts'

const Mapper = Vue.extend({
  computed: {
    ...dictMapper.mapGetters(['organizations'])
  },
  methods: {
    ...dictMapper.mapActions(['fetchAllOrganizations'])
  }
})

@Component
export default class EditAddModal extends Mapper {
  @Prop({ type: String, default: 'Редактирование пользователя' })
  readonly title!: string
  @Prop(String) editUserModalId!: string
  @Prop(String) type?: 'add' | 'edit'

  private textButton = 'Пригласить'
  private roles_ = roles
  private errorText = ''

  private userModel = UserFactory.emptyUser()
  private studentClassModel = UserFactory.emptyClassModel()

  public openEditModal(params: any) {
    this.userModel = params
    this.setSchemaForStudent()
    if (this.userModel.roles.includes(roles.student)) {
      this.studentClassModel.class = this.userModel.class
    }
  }

  private async getOrganizations() {
    await this.fetchAllOrganizations()
    this.modalSchema_.fields!.find(
      item => item.model === 'organizations'
    )!.options = this.organizations
  }

  private created() {
    if (this.type === 'edit') {
      this.textButton = 'Сохранить изменения'
    }
    this.getOrganizations()
  }

  private listRoles = Object.keys(rolesTranslation).map(key => ({
    text: rolesTranslation[key],
    value: key
  }))

  private listMoodleRights = Object.keys(moodleTranslation).map(key => ({
    text: moodleTranslation[key],
    value: key
  }))

  private isDisableStudentRole(params: boolean) {
    this.modalSchema_.fields!.find(
      item => item.model === 'moodleRights'
    )!.disabled = params
    const rolesValue = this.modalSchema_.fields!.find(
      item => item.model === 'roles'
    )!.values
    for (const value of rolesValue) {
      if (value.value !== roles.student) {
        value.disabled = params
      }
    }
  }

  private setSchemaForStudent() {
    /* Закомментированный функционал не позволяет назначить какую-либо роль кроме студента */
    // if (this.userModel.roles.includes(roles.student)) {
    //   if (this.userModel.roles.length > 1) {
    //     this.userModel.roles = [roles.student]
    //   }
    //   this.isDisableStudentRole(false)
    // }
    this.isDisableStudentRole(false)
  }

  private modalSchema_: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'email',
        model: 'email',
        label: 'Почта',
        placeholder: 'student-leti@etu.ru ',
        required: true,
        onChanged: this.validateUser
      },
      {
        type: 'bootstrap',
        inputType: 'text',
        model: 'surname',
        label: 'Фамилия',
        placeholder: 'Иванов',
        required: true,
        onChanged: this.validateUser
      },
      {
        type: 'bootstrap',
        inputType: 'text',
        model: 'name',
        label: 'Имя',
        placeholder: 'Иван',
        required: true,
        onChanged: this.validateUser
      },
      {
        type: 'bootstrap',
        inputType: 'text',
        model: 'midname',
        label: 'Отчество',
        placeholder: 'Иванович',
        required: false,
        onChanged: this.validateUser
      },
      {
        type: 'bootstrapRadioGroup',
        model: 'moodleRights',
        label: 'Права на Moodle',
        values: this.listMoodleRights,
        required: true,
        disabled: false
      },
      {
        type: 'bootstrapCheckboxGroup',
        model: 'roles',
        label: 'Выберите роли',
        values: this.listRoles,
        required: true,
        multiple: true,
        onChanged: this.setSchemaForStudent
      },
      {
        type: 'multiselect',
        label: 'Организация',
        model: 'organizations',
        placeholder: 'Выберите организацию',
        styleClasses: 'mt-1',
        trackBy: 'id',
        options: [],
        multiple: true,
        required: true,
        customLabel({ title }: any) {
          return title ? title : 'Выберите значение'
        }
      }
    ]
  }

  private classSchema_: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'number',
        model: 'class',
        label: 'Класс/Курс',
        placeholder: 'Введите Класс/Курс',
        min: 1,
        max: 11,
        onChanged: this.validateUser
      }
    ]
  }

  private async onSaveCallback() {
    if (this.userModel.roles.includes(roles.student)) {
      this.userModel.class = this.studentClassModel.class
    }
    const ourOrgs = this.userModel.organizations
    this.userModel.email = this.userModel.email.toLowerCase()
    this.userModel.organizations = ourOrgs.map(ourOrg => ourOrg.id)
    const postUser = _.cloneDeep(this.userModel)
    const userId = postUser.id
    if (userId) {
      this.editUserModalId === 'addUser'
        ? await AdminAPI.addUser(postUser)
        : await AdminAPI.setUser(postUser, userId)
      this.$emit('reload')
    }
  }

  private validateUser() {
    this.errorText = ''
    if (!regFIO.test(this.userModel.name)) {
      this.errorText = 'Проверьте правильность ввода имени'
    }

    if (!regFIO.test(this.userModel.surname)) {
      this.errorText = 'Проверьте правильность ввода фамилии'
    }
    if (!regMail.test(this.userModel.email)) {
      this.errorText = 'Проверьте правильность ввода почты'
    }
    if (this.userModel.roles.includes(roles.student)) {
      if (_.isString(this.studentClassModel.class)) {
        this.studentClassModel.class = parseInt(this.studentClassModel.class)
      }
      if (
        _.isNumber(this.studentClassModel.class) &&
        !_.isNaN(this.studentClassModel.class)
      ) {
        if (
          this.studentClassModel.class < 1 ||
          this.studentClassModel.class > 11
        ) {
          this.errorText = 'Проверьте правильность ввода класса/курса - 1-11'
        }
      } else {
        this.errorText =
          'Проверьте правильность ввода класса/курса - введено неверное число'
      }
    }
    if (
      !_.isNil(this.userModel.midname) &&
      !_.isEmpty(this.userModel.midname)
    ) {
      if (!regFIO.test(this.userModel.midname as string)) {
        this.errorText = 'Проверьте правильность ввода отчества'
      }
    }
  }
  get isEditMode() {
    this.validateUser()
    if (this.errorText) {
      return false
    }
    return !_.isEmpty(this.userModel.roles)
  }

  private onHidden() {
    this.userModel = UserFactory.emptyUser()
    this.studentClassModel = UserFactory.emptyClassModel()
  }
}
